import dayjs from 'dayjs';
import { useState } from 'react';
import { getDataWithToken, to, postDataWithToken, putDataWithToken } from '../../utils/request';
import { CommonListType } from './types/common';
import type { ResumeItemProps, ResumeRemarks, ResumeWorkHistory } from './types/resume';
enum requestUrl {
  getResumeList = '/companies/{hrtj_company_id}/resumes/',
  getMiniAppResumeList = '/career_fair/companies/{hrtj_company_id}/resume/list/',
  getMiniAppResumeDetail = '/career_fair/companies/{hrtj_company_id}/resume/{submit_id}/',
  exportMiniAppResumes = '/career_fair/companies/{hrtj_company_id}/resume/export/',
  getMyResumeList = '/companies/{hrtj_company_id}/resumes/self_resume_library/',
  uploadResume = '/companies/{hrtj_company_id}/resumes/analysis/',
  getResumeDetail = '/companies/{hrtj_company_id}/resumes/{resume_id}/',
  getMyResumeDetail = '/companies/{hrtj_company_id}/resumes/self_resume_library/{resume_id}/',
  resumeInProject = '/companies/{hrtj_company_id}/resumes/{resume_id}/job_list/',
  myProjects = 'companies/{hrtj_company_id}/jobs/simple_job_info/',
  getResumeRemarks = '/companies/{hrtj_company_id}/resumes/{resume_id}/resume_remarks/',
  createResumeRemark = '/companies/{hrtj_company_id}/resumes/{resume_id}/resume_remarks/',
  addResumeToProject = '/companies/{hrtj_company_id}/jobs/{job_id}/pull_resumes/{resume_id}/',
  getResumeProjectList = '/companies/{hrtj_company_id}/resumes/jobs/',
  getResumeEmailPhone = '/companies/{hrtj_company_id}/resumes/{resume_id}/get_resume_info/',
  getInterviewResumes = '/companies/{hrtj_company_id}/resumes/interview_resumes/',
  exportInterViewResume = '/companies/{hrtj_company_id}/resumes/interview_resumes_export/',
  inductionResumes = '/companies/{hrtj_company_id}/resumes/induction_resumes/',
  exportInductionResumes = '/companies/{hrtj_company_id}/resumes/induction_resumes_export/',
  interviewResumeModifyInfo = '/companies/{hrtj_company_id}/resumes/interview_resume_modify_info/{resume_record_id}/',
  manageResumeDetail = '/companies/{hrtj_company_id}/resumes/manage_resumes/{resume_record_id}/',
  batchObtainResumes = '/companies/{hrtj_company_id}/resumes/batch_pull_resumes/',
  batchReviseObtainResumes = '/user_roles/companies/{hrtj_company_id}/batch_revise_resumes/',
  multipleBatchObtainResumes = 'companies/{hrtj_company_id}/resumes/self_resume_library/batch_pull_resumes/',
  //修改离职
  onOfficeResumeModifyInfo = '/companies/{hrtj_company_id}/resumes/interview_resume_modify_info/{resume_record_id}/',
  //查看离职简历列表
  resignResumeList = '/companies/{hrtj_company_id}/resumes/resign_resumes/',
  //导出离职简历列表
  exportResignResumeList = '/companies/{hrtj_company_id}/resumes/resign_resumes_export/',
  //获取沟通状态
  resumeRecordStatus = '/companies/{hrtj_company_id}/resumes/resume_record_information/{record_id}/communication_process/',
  //修改沟通状态
  resumeRecordStatusModify = '/companies/{hrtj_company_id}/resumes/resume_record_information/{record_id}/communication_process/',
  //工作经历
  resumeWorkHistory = '/companies/{hrtj_company_id}/resumes/{resume_id}/history_company/',
  //获取通过面试的简历列表
  interviewPassResumes = '/companies/{hrtj_company_id}/resumes/interview_pass_resumes/',
  //导出通过面试的简历列表
  exportInterviewPassResumes = '/companies/{hrtj_company_id}/resumes/interview_pass_resumes_export/',
  //面试不通过备注
  interviewResumeMarks = '/companies/{hrtj_company_id}/resumes/{resume_id}/resume_remarks/',
  //创建简历
  createResume = '/companies/{hrtj_company_id}/resumes/fill_single_resume/ ',
  // 公司在各个阶段处理中的简历
  processStatusResume = '/companies/{hrtj_company_id}/resumes/process_status_resume_list/',
  exportResumeProgress = '/companies/{hrtj_company_id}/resumes/export_process_status_resume/',
  getProgressResumeDetail = '/companies/{hrtj_company_id}/resumes/process_resume_detail/{record_id}/',
}
//获取全部简历
export function useResume(companyId: string) {
  let [processResumeList, setProcessResumeList] = useState<CommonListType<ResumeItemProps>>({
    count: 0,
    next: '',
    results: [],
  });
  let [resumeList, setResumeList] = useState<CommonListType<ResumeItemProps>>({
    count: 0,
    next: '',
    results: [],
  });
  let [miniAppResumeList, setMiniAppResumeList] = useState<CommonListType<ResumeItemProps>>({
    count: 0,
    next: '',
    results: [],
    loading: false,
  });

  let [myResumeList, setMyResumeList] = useState<CommonListType<ResumeItemProps>>({
    count: 0,
    next: '',
    results: [],
  });
  const [miniAppResumeDetail, setMiniAppResumeDetail] = useState<any>({ loading: false, data: {} });

  let [interviewResumeList, setInterviewResumeList] = useState<CommonListType<ResumeItemProps>>({
    count: 0,
    next: '',
    results: [],
  });
  let [onOfficeResumeList, setOnOfficeResumeList] = useState<CommonListType<ResumeItemProps>>({
    count: 0,
    next: '',
    results: [],
  });
  const [manageResumeDetail, setManageResumeDetail] = useState<any>({});
 

  // 公司在各个阶段处理中的简历
  async function getProcessResumeList(params: any) {
    let url = requestUrl.processStatusResume.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      setProcessResumeList(res.data);
    }
  }

  async function getResumeList(params: any) {
    let url = requestUrl.getResumeList.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      setResumeList(res.data);
    }
  }
  async function getMiniAppResumeList(params: any) {
    let url = requestUrl.getMiniAppResumeList.replace('{hrtj_company_id}', companyId);
    setMiniAppResumeList({
      ...miniAppResumeList,
      loading: true,
    });

    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      return setMiniAppResumeList({ ...res.data, loading: false });
    }
    setMiniAppResumeList({
      ...miniAppResumeList,
      loading: false,
    });
  }
  async function exportMiniAppResumes(params: any) {
    let url = requestUrl.exportMiniAppResumes.replace('{hrtj_company_id}', companyId);

    const [error, res] = await to(getDataWithToken(url, params,'blob'));
    if (!error) {
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      console.log(blob)
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.setAttribute('download', '川思直聘个人端简历.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return res.data;
    }
  }
  async function getMiniAppResumeDetail(resume_id: any) {
    let url = requestUrl.getMiniAppResumeDetail

      .replace('{submit_id}', resume_id)
      .replace('{hrtj_company_id}', companyId || '');
    setMiniAppResumeDetail({ ...miniAppResumeDetail, loading: true });
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      return setMiniAppResumeDetail({ data: res.data, loading: false });
    }
    setMiniAppResumeDetail({ ...miniAppResumeDetail, loading: false });
  }
  async function getMyResumeList(params: any) {
    let url = requestUrl.getMyResumeList.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      setMyResumeList(res.data);
    }
  }

  async function getInterviewResumeList(params: any) {
    let url = requestUrl.getInterviewResumes.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      setInterviewResumeList(res.data);
      return Promise.resolve(res.data);
    }
  }
  async function getOnOfficeResumeList(params: any) {
    let url = requestUrl.inductionResumes.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      setOnOfficeResumeList(res.data);
      return Promise.resolve(res.data);
    }
  }
  async function putInterviewResumeModifyInfo(resume_record_id: any, data: any) {
    let url = requestUrl.interviewResumeModifyInfo
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_record_id}', resume_record_id);
    const [error, res] = await to(putDataWithToken(url, data));
    if (!error) {
      // setOnOfficeResumeList(res.data);
      return Promise.resolve(res.data);
    }
  }

  async function getExportInterViewResumeData(params: any) {
    let url = requestUrl.exportInterViewResume.replace('{hrtj_company_id}', companyId);
    let [err, res] = await to(getDataWithToken(url, params));
    if (!err) {
      // console.log(res);
      window.open(res.data.excel_file_url);
    }
  }
  async function getExportInductionResumesData(params: any) {
    let url = requestUrl.exportInductionResumes.replace('{hrtj_company_id}', companyId);
    let [err, res] = await to(getDataWithToken(url, params));
    if (!err) {
      // console.log(res);
      window.open(res.data.excel_file_url);
    }
  }
  //上传简历
  async function uploadResume(data: any) {
    let url = requestUrl.uploadResume.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(postDataWithToken(url, data));
    return { error, res };
  }

  //获取简历详情
  const [resumeDetail, setResumeDetail] = useState<any>({});
  async function getResumeDetail(resumeId: string) {
    let url = requestUrl.getResumeDetail
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_id}', resumeId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      setResumeDetail(res.data);
    } else {
      setResumeDetail({ isError: true });
    }
  }

  //获取简历备注
  const [resumeMarks, setResumeMarks] = useState<ResumeRemarks>({});
  async function getResumeMarks(resumeId: string) {
    let url = requestUrl.getResumeRemarks
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_id}', resumeId.toString());
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      setResumeMarks(res.data);
    }
  }

  //创建简历备注
  async function createResumeRemark(resumeId: string, content: string, kind = 1) {
    let url = requestUrl.createResumeRemark
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_id}', resumeId);
    const [error, res] = await to(postDataWithToken(url, { content, kind }));
    if (!error) {
      let temp = [...resumeMarks.results!];
      temp.unshift({ content, created_at: dayjs().format() });
      console.log(resumeDetail, manageResumeDetail);
      setResumeMarks({ ...resumeMarks, results: temp });
      // 创建成功后本地更新
      if (Object.keys(resumeDetail).length) {
        setResumeDetail({
          ...resumeDetail,
          resume_mark: { content, created_at: dayjs().format() },
        });
        return;
      }
      if (Object.keys(manageResumeDetail).length) {
        setManageResumeDetail({
          ...manageResumeDetail,
          resume_mark: { content, created_at: dayjs().format() },
        });
      }
    }
  }

  //将简历拉取到项目中
  const [resumeToProject, setResumeToProject] = useState('');
  async function addResumeToProject(jobId: number, resumeId: string, data: number[]) {
    let url = requestUrl.addResumeToProject
      .replace('{hrtj_company_id}', companyId)
      .replace('{job_id}', jobId.toString())
      .replace('{resume_id}', resumeId);
    const [error, res] = await to(postDataWithToken(url, data));
    if (!error) {
      setResumeToProject(res.data);
      getResumeProjectList(resumeId);
      getResumeInProject(resumeId);
      return res.data;
    }
  }
  //简历详情中的项目列表
  const [resumeProjectList, setResumeProjectList] = useState<CommonListType>();
  async function getResumeProjectList(resumeId?: string, search_content?: string) {
    let url = requestUrl.getResumeProjectList.replace('{hrtj_company_id}', companyId);
    let params: {
      limit?: number;
      offset?: number;
      resume_id?: string;
      search_content?: string;
    } = {
      limit: 999,
      offset: 0,
    };
    if (resumeId) {
      params['resume_id'] = resumeId;
    }
    if (search_content) {
      params['search_content'] = search_content;
    }
    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      setResumeProjectList(res.data);
    }
  }
  //获取简历的邮箱和手机号
  async function getResumeEmailPhone(resumeId: string) {
    let url = requestUrl.getResumeEmailPhone
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_id}', resumeId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      setResumeDetail({
        ...resumeDetail,
        email: res.data.email,
        phone: res.data.phone,
      });
    }
  }

  async function getManageResumeDetail(resumeId: string) {
    let url = requestUrl.manageResumeDetail
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_record_id}', resumeId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      setManageResumeDetail(res.data);
    }
  }

  //获取所在项目
  const [resumeInProjectList, setResumeInProjectList] = useState<CommonListType>();
  async function getResumeInProject(resumeId: string) {
    let url = requestUrl.resumeInProject
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_id}', resumeId);
    const [err, res] = await to(getDataWithToken(url));
    if (!err) {
      console.log(res);
      setResumeInProjectList(res.data);
    }
  }

  //获取简历详情
  const [myResumeDetail, setMyResumeDetail] = useState<any>({});
  async function getMyResumeDetail(resumeId: string) {
    let url = requestUrl.getMyResumeDetail
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_id}', resumeId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      setMyResumeDetail(res.data);
    } else {
      setMyResumeDetail({ isError: true });
    }
    return res.data;
  }
  //获取简历详情
  const [progressResumeDetail, setProgressResumeDetail] = useState<any>({});
  async function getProgressResumeDetail(recordId: string) {
    let url = requestUrl.getProgressResumeDetail
      .replace('{hrtj_company_id}', companyId)
      .replace('{record_id}', recordId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      setProgressResumeDetail(res.data);
    } else {
      setProgressResumeDetail({ isError: true });
    }
    return res.data;
  }

  //获取个人简历库中的项目列表
  const [myProjects, setMyProjects] = useState<any>([]);
  async function getMyProjects(query: any) {
    let url = requestUrl.myProjects.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url, query));
    if (!error) {
      setMyProjects(res.data.results);
    }
  }

  //批量获取
  // const [] = useState()
  async function multipleBatchResumes(data: { job_id: number | string; resume_ids: number[] }) {
    let url = requestUrl.multipleBatchObtainResumes.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(postDataWithToken(url, data));
    if (!error) {
      return res;
    } else {
      return Promise.reject(error);
    }
  }
  //批量获取
  // const [] = useState()
  async function BatchResumes(data: { job_id: number | string; resume_ids: number[] }) {
    let url = requestUrl.batchObtainResumes.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(postDataWithToken(url, data));
    if (!error) {
      return res.data;
    } else {
      return Promise.reject(error);
    }
  }
  async function BatchReviseResumes(data: {  resume_ids: number[] }) {
    let url = requestUrl.batchReviseObtainResumes.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(postDataWithToken(url, data));
    if (!error) {
      return res.data;
    } else {
      return Promise.reject(error);
    }
  }
  //修改离职
  async function put() {}
  //获取离职列表
  const [resignList, setResignList] = useState<CommonListType<ResumeItemProps>>({
    count: 0,
    next: '',
    results: [],
  });
  async function getResignList(params: any = {}) {
    let url = requestUrl.resignResumeList.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url, params));
    // debugger
    if (!error) {
      console.log(res.data);
      setResignList(res.data);
    }
    return null;
  }
  //导出离职列表数据
  async function exportResignResumeList() {
    let url = requestUrl.exportResignResumeList.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      window.open(res.data.excel_file_url);
    }
  }
  //获取简历沟通状态
  const [resumeRecordStatus, setResumeRecordStatus] = useState('');
  async function getResumeRecordStatus(recordId: string) {
    let url = requestUrl.resumeRecordStatus
      .replace('{hrtj_company_id}', companyId)
      .replace('{record_id}', recordId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      setResumeRecordStatus(res.data.communication_process);
    }
  }

  //修改简历沟通状态
  async function putResumeRecordStatus(recordId: string, value: string[]) {
    let url = requestUrl.resumeRecordStatusModify
      .replace('{hrtj_company_id}', companyId)
      .replace('{record_id}', recordId);
    const [error, res] = await to(putDataWithToken(url, value));
    console.log('%c [ res ]-376', 'font-size:13px; background:pink; color:#bf2c9f;', res);
    if (!error) {
      return res;
    }
  }
  //简历面试和入职记录
  const [resumeWorkHistory, setResumeWorkHistory] = useState<ResumeWorkHistory[]>([]);
  async function getResumeWorkHistory(resumeId: string) {
    let url = requestUrl.resumeWorkHistory
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_id}', resumeId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      console.log(res);
      res.data&&setResumeWorkHistory(res.data);
    }
  }

  //通过面试的简历
  const [interviewPassResumes, setInterviewPassResumes] = useState<CommonListType<ResumeItemProps>>(
    {
      count: 0,
      next: '',
      results: [],
    }
  );
  async function getInterviewPassResumes(params: any = {}) {
    let url = requestUrl.interviewPassResumes.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url, params));
    if (!error) {
      setInterviewPassResumes(res.data);
    }
  }
  //导出通过面试
  async function exportInterviewPassResumes() {
    let url = requestUrl.exportInterviewPassResumes.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(getDataWithToken(url));
    if (!error) {
      window.open(res.data.excel_file_url);
    }
  }
  //添加简历备注
  async function postResumeRemarks(resume_id: string, resume_record_id: any, content: string) {
    let url = requestUrl.interviewResumeMarks
      .replace('{hrtj_company_id}', companyId)
      .replace('{resume_id}', resume_id);
    const [error, res] = await to(postDataWithToken(url, { content, kind: 3, resume_record_id }));
    if (!error) {
      return res;
    }
  }

  //创建简历
  async function createResume(data: any) {
    let url = requestUrl.createResume.replace('{hrtj_company_id}', companyId);
    const [error, res] = await to(postDataWithToken(url, data));
    if (!error) {
      return res.data;
    }
  }
  async function exportResumeProgress(params: any) {
    let url = requestUrl.exportResumeProgress.replace('{hrtj_company_id}', companyId);
    let [err, res] = await to(getDataWithToken(url, params));
    if (!err) {
      // console.log(res);
      window.open(res.data.excel_file_url);
    }
  }
  return {
    myProjects,
    getMyProjects,
    myResumeDetail,
    getMyResumeDetail,
    resumeProjectList,
    getResumeProjectList,
    resumeList,
    getResumeList,
    resumeDetail,
    getResumeDetail,
    resumeMarks,
    getResumeMarks,
    //公司在各个阶段处理中的简历
    processResumeList,
    getProcessResumeList,
    myResumeList,
    getMyResumeList,
    resumeInProjectList,
    getResumeInProject,
    //离职简历的api以及数据
    resignList,
    getResignList,
    exportResignResumeList,
    //简历沟通状态 数据  获取数据  修改数据
    resumeRecordStatus,
    getResumeRecordStatus,
    putResumeRecordStatus,
    //简历面试和入职记录 数据  获取数据
    resumeWorkHistory,
    getResumeWorkHistory,
    //通过面试 数据 获取 导出
    interviewPassResumes,
    getInterviewPassResumes,
    exportInterviewPassResumes,
    BatchReviseResumes,
    // 创建简历
    createResume,
    createResumeRemark,
    uploadResume,
    getResumeEmailPhone,
    resumeToProject,
    addResumeToProject,
    getInterviewResumeList,
    interviewResumeList,
    onOfficeResumeList,
    getOnOfficeResumeList,
    putInterviewResumeModifyInfo,
    getExportInterViewResumeData,
    getExportInductionResumesData,
    //添加简历备注
    postResumeRemarks,
    manageResumeDetail,
    getManageResumeDetail,
    BatchResumes,
    multipleBatchResumes,
    //小程序简历
    miniAppResumeList,
    miniAppResumeDetail,
    getMiniAppResumeList,
    exportMiniAppResumes,
    getMiniAppResumeDetail,
    exportResumeProgress,
    progressResumeDetail,
getProgressResumeDetail,
  };
}
