import React from 'react';
import dayjs from 'dayjs';
import './index.less';
import { ManOutlined, PhoneOutlined, WomanOutlined } from '@ant-design/icons';
import type { ResumeItemProps } from '../../../models/types/resume';
import { message } from 'antd';

const ResumeItem: React.FC<ResumeItemProps> = props => {
  let {
    name,
    avatar,
    age,
    expect_salary,
    processing_status,
    worked_years,
    created_at,
    sex,
    degree,
    expect_positions,
    updated_at,
    expect_employment_type,
    resume_remark_content,
    effective_time,
    resume_creator,
    interview_time,
    is_effective,
    isPublic,
    communication_process,
    job_title,
  } = props;
  // const typeColor: Record<
  //   string,
  //   {
  //     className: string;
  //     text: string;
  //   }
  // > = {
  //   "1": { className: "pending", text: "待处理" },
  //   "2": { className: "processed", text: "面试" },
  //   "4": { className: "expired", text: "录用" },
  // };

  const resumeStateList: Record<string, { color: string; text: string }> = {
    '1': { color: '#FC0E0E', text: '待处理' },
    '2': { color: '#1890FF', text: '面试' },
    '4': { color: 'green', text: '已入职' },
    '7': { color: '#cccccc', text: '未入职' },
    '8': { color: '', text: '已到面' },
    '9': { color: '', text: '面试通过' },
    '10': { color: '', text: '已离职' },
    '11': { color: '', text: '未通过面试' },
    '13': { color: '', text: '未到面' },
  };

  const state = resumeStateList[processing_status];
  const defaultImg =
    sex === '男'
      ? 'https://ats-mini-app-1257093263.cos.ap-shanghai.myqcloud.com/headshot/boy_1.jpg'
      : 'https://ats-mini-app-1257093263.cos.ap-shanghai.myqcloud.com/headshot/girl_1.png';

  const callPhone = (e: any) => {
    e.stopPropagation();

    if (!window.qiConnect) {
      // message.error('外呼功能初始化失败，请刷新后重试');
      return;
    }
    if (!props.phone) {
      message.error('手机号不存在');
      return;
    }
    if (window.qiConnect) {
      // qiConnect.call(props.phone);
    }
  };
  return (
    <div className="resume-item">
      <div className="resume-item-user">
        <div className="resume-item-info">
          <div className="resume-item-info-left">
            <img src={avatar === null ? defaultImg : avatar} alt="" />

            <div className="resume-item-info-left-sex">
              {sex === '男' ? (
                <ManOutlined style={{ fontSize: 16, color: '#0E79FD' }} />
              ) : (
                <WomanOutlined style={{ fontSize: 16, color: '#F575FA' }} />
              )}
            </div>
          </div>

          <div className="resume-item-info-right">
            <div>
              <div className="resume-item-info-right-name">{name}</div>
              {/* <div className="resume-item-info-right-salary">
                {expect_salary === null ? '无期望薪资' : expect_salary / 1000 + '/月'}
              </div> */}
              <div className="resume-item-info-right-jobName">
                {expect_positions ?? '无期望职位'}
              </div>
            </div>
            <div className="resume-item-info-right-comprehensive">
              {`${age ? age + '岁' : '无年龄'} ｜ ${degree || '无学历'}`}
            </div>
            {/* <div className="resume-item-info-right-comprehensive">
              {`${age ? age + '岁' : '无年龄'} ｜ ${degree || '无学历'} ｜ ${worked_years ? worked_years + '年经验' : '无工作经验'
                } ｜ ${expect_employment_type === null ? '无工作类型' : '找' + expect_employment_type
                }`}
            </div> */}
          </div>
        </div>
        <div className="resume-item-date">
          {Boolean(processing_status) ? (
            ''
          ) : (
            <>
              <div>入库时间：{dayjs(created_at).format('YYYY年M月D日 HH:mm')}</div>
              {effective_time ? (
                <div>
                  有效期至：
                  {effective_time ? dayjs(effective_time).format('YYYY年M月D日 HH:mm') : '-'}
                </div>
              ) : (
                <div>更新时间：{dayjs(updated_at).format('YYYY年M月D日 HH:mm')}</div>
              )}
            </>
          )}
          {processing_status === 1 ? (
            <>
              <div>添加时间：{dayjs(created_at).format('YYYY年M月D日 HH:mm')}</div>
              <div>
                有效期至：
                {effective_time ? dayjs(effective_time).format('YYYY年M月D日 HH:mm') : '-'}
              </div>
            </>
          ) : (
            ''
          )}

          {processing_status === 2 || processing_status === 4 ? (
            <>
              <div>入库时间：{dayjs(created_at).format('YYYY年M月D日 HH:mm')}</div>
              <div>面试时间：{dayjs(interview_time).format('YYYY年M月D日 HH:mm')}</div>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="resume-item-date">
            <div className="resume-item-remark">
              归属人：{resume_creator||'无'  }
            </div>
          <div className="resume-item-remark">
            备注：{resume_remark_content ? resume_remark_content : '无'}
          </div>
        </div>
        <div className="resume-item-date">
          {communication_process && <div>沟通状态：{communication_process}</div>}
          {job_title && <div>当前所属项目：{job_title}</div>}
        </div>
      </div>

      {/* {!processing_status && !isPublic && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 20 }}>
            <PhoneOutlined style={{ fontSize: 24 }} onClick={callPhone} />
          </div>
        </div>
      )} */}
      {processing_status && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="resume-item-status">
            <span style={{ color: state?.color, border: `1px solid ${state?.color}` }}>
              {state?.text}
            </span>
          </div>
          {/* <div style={{ marginLeft: 20 }}>
            <PhoneOutlined style={{ fontSize: 24 }} onClick={callPhone} />
          </div> */}
        </div>
      )}
    </div>
  );
};
export default ResumeItem;
