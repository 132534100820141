import { Cascader, Form, Select, DatePicker, Input, Button } from 'antd';
import { city } from '../../../utils/city';

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

const ChangeStatus: React.FC<{
  stateFinish: (stateValue: any, values: any) => void;
  setToolIndex: Function;
}> = props => {
  const { setToolIndex, stateFinish } = props;
  const [stateValue, setStateValue] = useState(undefined);
  const [form] = Form.useForm();
  const domElement = useRef<any>(null);
  const acNode = useRef<any>(null);
  const stateSelectChange = (e: any) => {
    setStateValue(e);
  };
  const finish = (values: any) => {
    stateFinish(stateValue, values);
  };

  useLayoutEffect(() => {
    const _window = window as any;
    if (_window.BMap && !acNode.current) {
      const BMap = _window.BMap;
      let ac = new BMap.Autocomplete({
        input: 'interview_address',
      });
      acNode.current = ac;
      ac.addEventListener('onconfirm', function (e: any) {
        //鼠标点击下拉列表后的事件
        let _value = e.item.value;
        let myValue =
          _value.province + _value.city + _value.district + _value.street + _value.business;

        form.setFieldsValue({
          interview_address: myValue,
        });
      });
    }

    return () => {
      if (acNode.current) {
        if (acNode.current.dispose) {
          acNode.current.dispose();
          acNode.current = null;
        }
      }
      if (domElement.current) {
        domElement.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      interview_time: undefined,
      interview_city: undefined,
      interview_address: undefined,
    });
    return () => {
      console.log('页面卸载');
    };
  }, []);

  const acNodeZIndexChange = () => {
    if (domElement.current !== null) {
      return;
    }
    const dom: any = document.querySelector('.tangram-suggestion-main');
    if (dom) {
      dom.style.zIndex = 9999;
      domElement.current = dom;
    }
  };

  const disableDate = (current: any) => {
    return dayjs().isAfter(current);
  };
  return (
    <div className="resume-detail-info-tool-state">
      {/* <div className="resume-detail-info-tool-title">修改状态</div> */}
      <div className="resume-detail-info-tool-title">邀请面试</div>
      <div className="resume-detail-info-tool-state-select">
        <Select
          style={{ width: '100%' }}
          onChange={stateSelectChange}
          placeholder={'请选择状态'}
          value={stateValue}>
          <Select.Option value={2}>面试</Select.Option>
          <Select.Option value={4} disabled>
            录用
          </Select.Option>
        </Select>
      </div>
      <Form layout="vertical" form={form} onFinish={finish}>
        <div
          className="resume-detail-info-tool-state-form"
          style={{ display: stateValue === 2 ? 'block' : 'none' }}>
          <div>
            <Form.Item
              name="interview_time"
              label="面试时间"
              rules={[{ required: true, message: '请选择时间' }]}>
              <DatePicker
                format={'YYYY-MM-DD HH:mm'}
                showTime
                placeholder="选择时间"
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item name="interview_city" label="面试地点">
              <Cascader placeholder="请选择城市" options={city} />
            </Form.Item>

            <Form.Item name="interview_address">
              <Input onFocus={acNodeZIndexChange} placeholder="请输入详细地址" />
            </Form.Item>
          </div>
        </div>
        <div className="resume-detail-info-tool-project-button">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              onClick={() => {
                setToolIndex(-1);
              }}>
              取消
            </Button>
            {stateValue === 2 ? (
              <Button type="primary" htmlType="submit">
                确认
              </Button>
            ) : (
              ''
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default ChangeStatus;
