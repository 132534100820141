import React, { useState } from 'react';
import { Empty, Checkbox, Select, Input, Tag, message, Space } from 'antd';

const ProjectItem: React.FC<{
  job_id: number;
  job_title: string;
  employment_type: string;
  recruitment_unit: string;
}> = props => {
  const { job_id, job_title, employment_type, recruitment_unit } = props;
  return (
    <div className="resume-detail-info-tool-project-item">
      {/* <Checkbox value={job_id}> */}
      <div style={{ marginLeft: 20 }}>
        <div className="resume-detail-info-tool-project-item-title">
          <div className="resume-detail-info-tool-project-item-title-job">{job_title}</div>
          <span className="resume-detail-info-tool-project-item-title-type">{employment_type}</span>
        </div>
        <div className="resume-detail-info-tool-project-item-company">
          招聘单位：{recruitment_unit}
        </div>
      </div>
      {/* </Checkbox> */}
    </div>
  );
};
// const tagMap =  new Map()

const AddToProject: React.FC<{ data: any[]; setProjectId: Function; searchProject: Function }> = ({
  data,
  setProjectId,
  searchProject,
}) => {
  const [tags, setTags] = useState<{ job_id: number; job_title: string }[]>([]);
  const [tagMap] = useState(new Map());
  const search = (e: string) => {
    searchProject(e);
  };
  const closeTag = (removeTag: number) => {
    const newTags = tags.filter(tag => tag.job_id !== removeTag);
    tagMap.delete(removeTag);
    setTags(newTags);
    setProjectId(Array.from(tagMap.keys()));
  };
  const addTag = (tagValue: any) => {
    console.log(tagValue.job_id, tagMap.get(tagValue.job_id));
    if (tagMap.get(tagValue.job_id)) {
      message.error('您已经选中了此项目');
    } else {
      tagMap.set(tagValue.job_id, '123');
      setTags([...tags, { ...tagValue }]);
      setProjectId(Array.from(tagMap.keys()));
    }
  };
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Input.Search placeholder="搜索项目" onSearch={search}></Input.Search>
      </div>

      <div className="resume-detail-info-tool-project-list">
        {data.length !== 0 ? (
          data.map(item => (
            <div onClick={() => addTag(item)} key={item.job_id}>
              <ProjectItem {...item} key={item.job_id} />
            </div>
          ))
        ) : (
          <Empty />
        )}
      </div>

      <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
        <div style={{ whiteSpace: 'nowrap' }}>已选中：</div>
        <div style={{ flex: 1 }}>
          {tags.map(item => {
            return (
              <Tag
                closable
                color="#108ee9"
                style={{ marginBottom: 10 }}
                onClose={e => {
                  e.preventDefault();
                  closeTag(item.job_id);
                }}>
                {item.job_title}
              </Tag>
            );
          })}
        </div>

        {/* <Tag color="#108ee9">测试</Tag> */}
      </div>
    </>
  );
};
export default AddToProject;
